export enum LeadPMSTypes {
  yardi = 'yardi',
  entrata = 'entrata',
  email = 'email',
  Knock = 'knock'
}

export interface IReferralLeadFormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  tc?: boolean;
  confirmationEmail?: boolean;
  desiredMoveInDate?: string;
}

export interface IReferralConfigSetting {
  communityLead: boolean;
  communityLeadStart: string;
  defaultCobuPayout: string;
  communityLeadsTandC?: string;
  propertyWebsiteURL?: string;
  agentName?: string;
  leadSourceName?: string;
  leadPMSOpted?:
    | LeadPMSTypes.entrata
    | LeadPMSTypes.yardi
    | LeadPMSTypes.email
    | LeadPMSTypes.Knock;
}
export interface IReferralSettingEntrataPMS {
  entrataUuid?: string;
  entrata_domain?: string;
  entrata_property_id: string;
  entrata_username?: string;
  entrata_password?: string;
  entrataLeadSourceId?: string;
  default?: boolean;
}
export interface IReferralSettingYardiPMS {
  yardiUuid?: string;
  yardi_property_id?: string;
  yardiLeadSourceId?: string;
  default?: boolean;
}
export interface IReferralSettingDirectEmails {
  directEmails?: string;
}

export interface IReferralSettingKnockEmail {
  knockEmail?: string;
}

export interface IYardiCredentialPMS {
  yardiUuid?: string;
  username?: string;
  password?: string;
  database?: string;
  serverName?: string;
  webServiceUrl?: string;
}

export interface IReferralSettingForm
  extends IReferralSettingEntrataPMS,
    IReferralSettingYardiPMS,
    IReferralConfigSetting,
    IReferralSettingDirectEmails,
    IReferralSettingKnockEmail {}
